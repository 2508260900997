<template>
	<div :class="{ contain: true, mobile: isMobile }" >
		<div class="top">
			<div class="ban">
				<img v-if="src" :src="src" alt="" />
			</div>
			<div class="topbox d-flex flex-lg-row flex-row" style="background-color: rgb(39, 6, 69, 0.7)">
				<div class="img" v-if="src && !isMobile">
					<img  :src="src" alt="" />
				</div>
				<div class="right d-flex flex-column">
					<div class="box" v-if="src">
						<!-- <div class="toMarket" v-if="this.collectSource != 2 &&this.collectSource != 1&&this.collectSource != 5 && this.ownerName" @click="toMarket">To the market collection  ></div> -->
						<div class="ava d-flex flex-row justify-space-between">
							<div class="d-flex flex-row">
									<Avatar :did="collectItem.owner" :size="51" :hash="collectItem.profilePhoto"  imgType="middlev"
                :grade="collectItem.grade" showAvatarInfo />
							<div class="d-flex flex-column ml-7 ml-lg-4 createdby">
								<div class="nameBy">{{ $t("created_by")}}</div>
								<div class="name">
									{{
                    collectItem.userName
                      ? collectItem.userName
                      : collectItem.owner.substr(0, 12) +
                        "..." +
                        collectItem.owner.substr(-12)
                  }}
								</div>
							</div>
							</div>
							<div class="d-flex flex-row  nftcount" v-if="!isMobile">
							<div class="sub">NFTs</div>
							<div class="num ml-2">{{ amount }}</div>
						</div>
						
						</div>
						<div class="desc mt-4">
							{{ collectItem.name }}
						</div>
						
						<img class="message" width="20px" height="20px" src="@/assets/icons/btn_message_w.png" alt=""
							@click="msgBtn" v-if="collectItem.owner != this.$store.state.did" />
						<img class="message" width="20px" height="20px" src="@/assets/icons/icon_edit_w.png" alt=""
							@click="editCollection" @mouseenter="moused()"   @mouseleave="leave()" v-else-if="collectItem.owner == this.$store.state.did && this.collectSource == 5" />
						 <div class="explain d-flex flex-row align-center" v-if="isshow">
               				 <span>{{ $t("edit_folder")}}</span>
             			 </div>
						   <img class="complaint"   @click.stop="mousedComplaint()" v-if="this.$store.state.did && collectItem.owner != this.$store.state.did && collectItem.contractStatus != 2 && collectItem.contractStatus != 4 " src="@/assets/icons/icon_complaint_w.png" width="20px" height="20px" alt="" />
						  <img class="complaint" v-if="(collectItem.disabled == 2 || collectItem.disabled == 4)&& collectItem.owner == this.$store.state.did" src="@/assets/icons/ban.png" width="20px" height="20px" alt="" @click="AppealBtn" @mouseenter="mousedAppeal()"   @mouseleave="leaveAppeal()"  />
						   <img class="complaint" v-if="(collectItem.disabled == 2 || collectItem.disabled == 4) && collectItem.owner != this.$store.state.did" src="@/assets/icons/ban.png" width="20px" height="20px" alt=""   @mouseenter="mousedAppeal()"   @mouseleave="leaveAppeal()" />
						   <div
							class="explains "
							v-if="isshowComplaint"
							>
						    <div class="hideNfts" @click="hideNfts('folder')">{{$t('complain_folder')}}</div>
              				<div class="hideNfts" @click="hideCreator('creator')">{{$t('complain_creator')}}</div>
							<div class="hideNfts" @click="nftPlaintBtn">{{$t('popupFolderComplaints')}}</div>
          				  </div>
						  <div class="appealexplain d-flex flex-row align-center" v-if="isshowAppeal">
               				 <span>{{ $t("restricted_nft")}}</span>
             			 </div>
						 
						   <img
								class="icon_collect"
								src="@/assets/icons/icon_folder.png"
								width="20px"
								height="20px"
								alt=""
								@mouseenter="mousedCollect()"
								@mouseleave ="leaveCollect()"
           				 />
							<div class="explainCollect d-flex flex-row align-center" v-if="isshowCollect">
               				 <span>{{ $t("collection_explain")}}</span>
             			 </div>
						<div class="rb_icon d-flex justify-end">
							<img class="" src="@/assets/icons/icon_share_w.png" width="22px" height="22px" alt=""
								style="cursor: pointer" @click.stop="shareBtn" />
							<div class="share" v-if="openShare">
								<div class="d-flex flex-row mt-lg-5 mt-5 pointer" style="cursor: pointer"
									:class="{ click: isCopy }" @click="share('copyLink', '')">
									<img class="mx-5" src="@/assets/icons/icon_IPFS.png" alt="Copy Link" />
									<span class="ml-0">
										{{ $t("carddetailShareCopyLink") }}
									</span>
								</div>
								<!-- <div class="d-flex flex-row mt-lg-6 mt-6 pointer " @click="toTweet"> -->
								<div class="d-flex flex-row mt-lg-6 mt-6 pointer" @click="share('twitter', sharePath)"
									style="cursor: pointer">
									<img class="mx-5" src="@/assets/icons/icon_twitter.png" alt="Tweet" />
									<span class="ml-0">Twitter</span>
								</div>
								<div class="d-flex flex-row mt-lg-6 mb-lg-4 mt-6 mb-4 pointer"
									@click="share('facebook', sharePath)" style="cursor: pointer">
									<v-icon class="mx-lg-5 ml-5 icon mx-5" color="primary" style="margin-top: -4px">
										mdi-facebook
									</v-icon>
									<span class="ml-0 pr-7">Facebook</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="tit">
			<p v-html="collectItem.description"></p>
		</div>
		<div class="cards-container">
			<div class="search_create">
				<div class="clearmode box d-flex flex-row">
					<!-- <div class="checkbox" v-if="typeStatus == 'createorPage' && !isMobile">
						<v-checkbox v-model="checkbox" @click="checkBoxClick(checkbox)"></v-checkbox>
					</div>
					<div class="content" v-if="typeStatus == 'createorPage' && !isMobile">
						{{ $t("Clean Mode") }}
					</div> -->
					<div class="image"  v-if="typeStatus == 'createorPage' "> 
						<div class="top_tab mb-4">
							<span class="btn mr-8" :class="{ btn2: ownerClick }" v-if="ownerClick && isShowCheckbox == 'isCheck'" @click="mode_click('owned')"
							>{{ $t("Owned") }}</span
						> 
							<span class="btn mr-8" :class="{ btn2: ownerClick }" v-else-if=" isShowCheckbox != 'isCheck'" @click="mode_click('owned')"
							>{{ $t("Owned") }}</span
						> 
							<span class="btn mr-8" :class="{ btn2: soldClick }" v-if="soldClick && isShowCheckbox == 'isCheck'"  @click="mode_click('sold')"
							>{{ $t("Sold") }}</span
						> 
							<span class="btn mr-8" :class="{ btn2: soldClick }" v-if="  isShowCheckbox != 'isCheck'"  @click="mode_click('sold')"
							>{{ $t("Sold") }}</span
						> 
						
						
						</div>
					</div>
					<CollectionInput style="width: 270px; margin-bottom: 20px" v-if="!isMobile  && isShowCheckbox != 'isCheck'"
						@change="searchChange" />
					
				
				</div>
				<div class="create d-flex flex-row">
				
						<div v-if="collectItem.owner != this.$store.state.did">
							<div class="owner"  v-if="this.collectSource != 2 &&this.collectSource != 1 && this.ownerName &&  this.owerfrom == 'createCollect'">
								{{ $t("owner_by")}} 
								<span class="ml-1" style="cursor: pointer"
									@mouseenter="mousedOwner()">{{ ownerName }}</span>
							</div>
							<div style="
                  position: absolute;
                  margin-left: -250px;
                  margin-top: 5px;
                  z-index: 1;
                ">
								<CreatorAvator :did="ownerBy" v-if="isShowAva" />
							</div>
						</div>
		<button class="subOnsale mr-2 mt-lg-2 mt-1" rounded v-if="
              typeStatus == 'createorPage' &&
              collectItem.owner == this.$store.state.did  && isShowCheckbox != 'isCheck'" @click="onDestroy" >
              			<div class="titleName">{{ $t("Remove_folder") }}</div>
            		</button>
			<SortSelect
			 v-if="typeStatus == 'createorPage'  && isShowCheckbox != 'isCheck'"
          class="mr-1 dot mt-lg-1 ml-8"
          :list="selectList"
          v-model="activeSort"
        />
        <img
		 v-if="typeStatus == 'createorPage'  && isShowCheckbox != 'isCheck'"
          :class="[rotate ? 'go' : '']"
          class="refresh"
          src="@/assets/icons/icon_refresh.png"
          @click="refresh"
        />
				</div>
			</div>

			<div class="cardlist">
				<div v-if="empty" class="empty">{{ $t("EmptyHere") }}</div>
				<div v-if="typeStatus == 'createorPage'" class="d-flex flex-wrap card">
					<div v-for="(item, i) in list" :key="i" >
						<Card  v-scroll="onScroll" :type="showModel == 'owned'? 'clearmode': cardType"  :value="item"
						owerfrom="my"
						 />
						<div>
						 <v-checkbox
						 	v-if="isShowCheckbox == 'isCheck' && showModel == 'sold'"
							class="check"
				
							color="#270645"
							:key="i"
							:value="item.checked"
							@click="childcheckboxChange(i, item)"
							hide-details					
       					 ></v-checkbox>
							<v-checkbox
						 	v-else-if="isShowCheckbox == 'isCheck' && showModel == 'owned'"
							class="ownerCheck"
							color="#270645"
							:key="i"
							:value="item.checked"
							@click="childcheckboxChange(i, item)"
							hide-details					
       					 ></v-checkbox>
						</div>
					</div>
					
				</div>
				<div v-else class="d-flex flex-wrap card">
					<Card v-for="(item, i) in list" :key="i" v-scroll="onScroll" :type="cardType" :value="item"
						:owerfrom="owerfrom" />
				</div>
			</div>
			  <loading :isShowLoading="isShowLoading"></loading>
		</div>
		 <div
           v-if="isShowCheckbox == 'isCheck'"
          class="totalbox d-flex justify-end align-center"
        >
		 <v-checkbox
            class="check mt-2"
            color="#ffffff"
			:label="label"
            hide-details
			 @click="checkboxChange"
			 :value="checkAllCard"
          ></v-checkbox>
          <p> {{ $t("nft_number") }}：{{ totalNumber }}</p>
          <div class="btnbox mr-5">
            <button class="subcancel" @click="subCancel">{{ $t("orderActionCancel") }}</button>
          </div>
          <button class="subsure " @click="subSure">{{ $t("countersign") }}</button>
        </div>
		<div class="right_bottom" v-if="!isMobile">
			<img src="@/assets/icons/btn_play1.png" @click="openCarousel" alt="" />
		</div>
		<div v-if="openmsg" style="position: fixed; right: 20px; bottom: 20px; z-index: 1">
			<message @getOpen="OpenMsg" :currfriend="currfriend"></message>
		</div>
		<v-dialog v-model="opencard">
			<carousel class="otherpage" :list="list" v-if="opencard" @getOpen="OpenCard"></carousel>
		</v-dialog>
		 <v-dialog v-model="openCreateCollection" v-if="openCreateCollection">
         <createCollection  @getOpen="OpenCreateCollection" :createType="createType" :NFTInfo="NFTInfo"></createCollection>
      </v-dialog>
	  	<v-dialog v-model="openCreateFolder" v-if="openCreateFolder">
         <createFolder  @getOpen="openCreateFolders"  :NFTInfo="NFTInfo"></createFolder>
      </v-dialog>
	  	   <!-- 投诉 -->
       <v-dialog v-model="nftComplaint">
      <nft-complaints
        :Nft_sku="collectItem"
		complaintType="folderComplaint"
        @getOpen="openNftcComp"
      ></nft-complaints>
    </v-dialog>
	          <!-- 被投诉提醒框 -->
    <v-dialog v-model="Banprompt">
      <banprompt v-if="Banprompt"  @getOpenBan="openBanprompt"></banprompt>
    </v-dialog>
	<!-- 申诉 -->
		<v-dialog v-model="Appeal">
			<nftAppeal  :parentValue="collectItem" appealType='folderAppeal' @getOpen="Open">
			</nftAppeal>
		</v-dialog>
	  <v-dialog v-model="openputonsale">
      <put-on-sale
	   :address="collectItem.collectItem"
        :onSaleTokenIds="onSaleTokenIds"
        :offSaleTokenIds="offSaleTokenIds"
		:NFTAmount="NFTAmount"
		:nftAddresss="nftAddresss"
        :supplyLimit="supplyLimit"
        :name="collectItem.name"
        :parentValue="collectItem"
		onsaleType="multi"
        @getOpen="openPutOnSale"	
      ></put-on-sale>
    </v-dialog>
      <!-- 结束售卖 -->
      <v-dialog v-model="Sold " v-if="Sold">
         <soldout
             :address="collectItem.collectItem"
             :onSaleTokenIds="onSaleTokenIds"
             :offSaleTokenIds="offSaleTokenIds"
             :name="collectItem.name"
			 offsaleType="multi"
			  :parentValue="collectItem"
             @getOpen="openSold"
         ></soldout>

      </v-dialog>
	   <!-- 销毁 -->
      <v-dialog v-model="Burning" v-if="Burning">
         <burn  :pageValue="collectItem" :address="collectItem.collectItem" :offSaleTokenIds="offSaleTokenIds" offsaleType="multi" @getOpen="openBurn"></burn>
      </v-dialog>
	    <!-- 移除作品夹 -->
       <v-dialog v-model="removefolder">
      <removefolder
        :parentValue="collectItem"
        :offSaleTokenIds="offSaleTokenIds"
        @getOpen="openFolder"
      ></removefolder>
    </v-dialog>
	 <uComponents ref="ucom"></uComponents>

	</div>
</template>

<script>
	import Avatar from "@/components/avatar/index.vue";
	import Carousel from "../../components/carousel";
	import message from "@/components/popup/message.vue";
	import CollectionInput from "@/components/CollectionInput.vue";
	import Card from "@/components/workCard/Card.vue";
	// import SortSelect from "@/components/SortSelect.vue";
	import PutOnSale from "@/components/popup/putOnSale.vue";
	import CreateCollection from "@/components/popup/createCollection";
	import SortSelect from '@/components/SortSelect.vue';
	import nftComplaints from "@/components/popup/nftComplaints.vue";
	 import Banprompt from "@/components/popup/banprompt.vue";
	 import NftAppeal from "@/components/popup/nftAppeal.vue";
	 import Soldout from "@/components/popup/soldout.vue";
	 import Burn from "@/components/popup/burn.vue";
	 import removefolder from "@/components/popup/removeFolder.vue";
	 import CreateFolder from "@/components/popup/createFolder";
	 import Loading from "@/components/loading.vue";
	 
	import {
		mapState
	} from "vuex";
	import api from "@/api";
	import {
		getFileSrc
	} from "@/utils/file";
	import {
		share
	} from "@/utils";
	import { getSettingArr } from "@/utils/helper";
	import CreatorAvator from "@/components/creatorAvatar/index";
	const COLLECTION_KEY = "COLLECTION";
	let last_time = 0;

	export default {
		name: "CollectionPage",
		components: {
			Loading,
			Carousel,
			message,
			Avatar,
			CollectionInput,
			Card,
			 SortSelect,
			PutOnSale,
			CreatorAvator,
			CreateCollection,
			NftAppeal,
			nftComplaints,
			Banprompt,
			Soldout,
			Burn,
			removefolder,
			CreateFolder
		},
		 data: function () {
			 return{
			 removefolder:false,
			Burning: false,
			nftAddresss:[],
			type:'',
			isShowCheckbox: "",
			nfType:'',
			Banprompt:false,
			 openCreateCollection:false,
		  openCreateFolder:false,
			opencard: false,
			open: false,
			openmsg: false,
			offset: true,
			pageInfo: {},
			did: "",
			currfriend: {},
			list: [],
			sku: {},
			typeStatus: "",
			showRightBottom: false,
			isShowLoading: false,
			isCopy: false,
			imgUrl: "",
			src: "",
			saleStatus: "",
			pageNumber: 1,
			pageSize: 20,
			ownerName: "",
			isshow:false,
			Sold: false,
			isshowComplaint:false,
			isshowCollect:false,
			isshowAppeal:false,
			nftComplaint: false,
  			selectList: [{
                    value: '0',
                    label:this.$t('market_filter_all')
                },
                {
                    value: '1',
                    label: this.$t('otherpageTabsPageOnsale')
                },
                {
                    value: '3',
                    label: this.$t('collection_tag_notonsale')
                },
                {
                    value: '4',
                    label: this.$t('token_state_end')
                },
            ],
			activeSort: '0',
			checkAllCard: false,
			isClick: true,
			isChange: false,
			showAllCheck: false,
			openShare: false,
			sharePath: "",
			totalNumber: 0,
			openputonsale: false,
			address: "",
			constructID: "",
			name: "",
			onSaleTokenIds: [],
			offSaleTokenIds: [],
			NFTAmount:[],
			NFTlist: [],
			supplyLimit: 2,
			collectItem: {},
			userData: {},
			url: "",
			empty: false,
			amount: 0,
			checkbox: false,
			cardType: "",
			collectSource: "",
			owerfrom: "firspublish",
			isShowAva: false,
			ownerBy: "",
			 rotate:false,
			   NFTInfo:{
        collectionImgUrl:'',
        name:'',
        linkUrl:'',
        description:'',
		collectionMetadata:'',
		id:'',
		totalPage:'',
		loading: false,
		searchInput:'',
		createType:'',
		category:'',
		nftAddress:'',
		contractCollectionId:''
		
	},
		showModel:'owned',
		soldClick:true,
		ownerClick:false,
		Appeal: false,
			 }
			
		
		},
		props: {
			collectfrom: {
				type: String,
			},
		},
		computed: {
			isMobile: function() {
				return this.$vuetify.breakpoint.mobile;
			},
			// handler: function() {
			// 	return {
			// 		ipt: this.searchInput,
			// 	};
			// },
			handler: function () {
      return {
        activeSort: this.activeSort,
      };
    },
			...mapState({
				searchInput: (state) => state.searchInput,
			}),
		},
		 watch: {
    handler: {
      handler: function () {
        this.list = [];
        this.pageNumber = 1;
        this.getColleectList();
      },
      deep: true,
    },
  },
	
		async mounted() {
			 this.label=this.$t("Select_All")
			  this.list.forEach((ele) => {
      ele.checked = false;
    });
	  window.eventBus.$on('collectionEdit', this.collectionEdit);
			this.showNoRead();
			window.addEventListener("click", this.clickOther);
			window.eventBus.$on('LangChange', this.onLangChange);
			let collection = this.$route.path;
			this.url = this.$route.params.id;

			if (this.$route.params.source == "firspublish") {
				this.collectSource = 1;
				this.owerfrom = "firspublish";
			} else if (this.$route.params.source == "detaillink") {
				this.collectSource = 2;
				this.owerfrom = "detaillink";
			} else if (this.$route.params.source == "otherpage") {
				this.collectSource = 5;
				this.owerfrom = "onsale";
			} else if (this.$route.params.source == "offsale") {
				this.collectSource = 4;
				this.owerfrom = "offsale";
			} else if (this.$route.params.source == "createFolder") {
				this.collectSource = 5;
				this.owerfrom = "createCollect";
			
			} else {
				this.collectSource = 1;
			}
      let linkUrl = this.$route.params.id;
      			await this.getColleectList();
			// let res_info = await api.home.collectionInfo({
			// 	linkUrl
			// });
			// this.collectItem = res_info.data;
			if (this.$route.params.source == "createFolder"){
					this.mode_click('sold')
			}
		
			console.log("wxl -- 44343",this.collectItem.id)
			  if(this.collectItem.nftType == 'ERC721'){
        this.nfType = '721'
      }else{
         this.nfType = '1155'
      }
			window.eventBus.$on("collectionPay", this.collection);
			if (collection.indexOf("collection_card") != -1) {
				this.typeStatus = "cardPage";
			} else {
				if (collection.indexOf("folder_create") != -1) {
					 if(this.$route.params.owner != this.$store.state.did)
					 {
						let targetUrl= window.location.href.replace("folder_create","folder").replace("createFolder","firspublish");
						
						 location.href=targetUrl;
					 }
					this.typeStatus = "createorPage";
				} else {
					this.typeStatus = "collectionPage";
				}
			}


			if (this.collectItem.description)
				this.collectItem.description = this.collectItem.description.replace(
					/\n/g,
					"<br/>"
				);

			this.src = await getFileSrc(COLLECTION_KEY, this.collectItem.imgUrl);

			let val_user = window.sessionStorage.getItem("UserData");
			this.userData = JSON.parse(val_user);

			if (this.typeStatus == "createorPage") {
					this.cardType = "my";
				} else {
					this.cardType = "other";
				}


			this.$forceUpdate();
		},
		methods: {
			share,
			searchChange(val) {
				this.searchInput = val
				clearTimeout(last_time);
				last_time = setTimeout(() => {
					this.list = [];
					this.pageNumber = 1;
					this.getColleectList();
				}, 600);
			},
		 refresh(){
			this.rotate=true;
			setTimeout(() => { this.rotate=false }, 2000);
				this.list = [];
					this.pageNumber = 1;
					this.getColleectList();
    
	},
	  childcheckboxChange(i, item) {
      console.log("6666666",item.checked)  
       this.list[i].checked = !this.list[i].checked;
	   this.nftList = this.list.filter(item=>item.checked)
	   this.checkAllCard = false
	   this.totalNumber = this.nftList.length
	   if(this.totalNumber ==this.list.length )
	   {
		   this.checkAllCard = true
	   }
	   console.log("wxl --- sqwwqe",this.nftList)
    },
	
	 //批量移除
    onDestroy() { 
        this.type = "destroy"
        this.totalNumber = 0
      this.isShowCheckbox = "isCheck";
   
     
	},
	collectionEdit(){
		location.reload();
	},
	 openSold(e) {
        this.Sold = e;
	  },
	   openFolder(e){
    this.removefolder=e
    },
	   openBurn(e) {
        this.Burning = e;
	  },
	  checkboxChange() {
      this.checkAllCard = !this.checkAllCard;
      // this.showAllCheck = this.checkAllCard;
      console.log("111111111", this.showAllCheck);
	  if( this.checkAllCard){
		  		  this.list.forEach((ele) => {
      ele.checked = true;
	});
	 this.nftList = this.list.filter(item=>item.checked)
	   this.totalNumber = this.nftList.length
	  }else{
		 this.list.forEach((ele) => {
      ele.checked = false;
    });
	  }
	  
      this.$forceUpdate();
    },
			createNft() {
			let imgObj={};
			imgObj.src = this.collectItem.imgUrl
			imgObj.name = this.collectItem.name
			imgObj.category = this.collectItem.category
			imgObj.nftAddress = this.collectItem.nftAddress
			imgObj.linkUrl = this.collectItem.linkUrl
			let json = localStorage.getItem("key_user_grade");
			let jsonObj = JSON.parse(json)

         if(jsonObj.grade == 2){
               this.Banprompt = true
               console.log("wxl ---- 2222")
            }else{
            //   this.$router.push({
			// 		name: "CreateNft",
			// 		query: {
			// 			createType: this.collectItem.nftType
			// 		},
			// 	});
			 this.$router.push({ name: "CreateNft", query: { createType: this.collectItem.nftType },params:{imgObj:imgObj}});
            }

				
			},
			mousedOwner() {
				this.isShowAva = true;
			},
			clickOther() {
				this.isShowAva = false;
				this.isShowOwner = false;
				this.openShare = false;
				 this.isshowComplaint = false
			},
			moused() {
				console.log("wxl --- 3333")
     			 this.isshow = true;
   			 },
   			 leave() {
     			 this.isshow = false;
				},
			mousedComplaint(){
				this.isshowComplaint = true
			},
			
			mousedCollect(){
				this.isshowCollect = true
			},
			leaveCollect(){
				this.isshowCollect = false
			},

			mousedAppeal(){
				this.isshowAppeal = true
			},
			leaveAppeal(){
				this.isshowAppeal = false
			},
			toMarket(){
				// this.$router.push({
				// 	name:"Launch"
				// })
				 let url
      if(this.collectItem.linkUrl)
      { 
       url = this.collectItem.linkUrl
      }else{
       url = this.collectItem.address 
      }
				let collectsource = "detaillink"
				let owner = this.collectItem.owner
			
				 this.$router.push({
          
             path: `/collection/${url}/${collectsource}/${owner}`,   
        });
			},
			  mode_click(mode) {
				  this.collectSource = 5;
				    this.pageNumber = 1;
      				this.list = [];
				   this.showModel = mode;
				   this.showStatus(mode)
				  this.getColleectList();
			  },
			    showStatus(mode){
      if(mode == "owned"){
       
        this.soldClick = false;
        this.ownerClick = true;
      }else if(mode == "sold"){
     
        this.soldClick = true;
        this.ownerClick = false;
      }

    },
			async getColleectList() {
				 if (this.loading) {
        		return;
			  }
				  this.loading = true;
				       this.isShowLoading = true
				let params = {
					pageNumber: this.pageNumber,
					pageSize: this.pageSize,
					// tokenType:
					searchKey: encodeURIComponent(this.searchInput || ""),
					// sort
					// folderId: this.collectItem.id,
					linkUrl: this.url,
					sourceType: this.collectSource,
					owner: this.$route.params.owner ? this.$route.params.owner :'',
					filterType:this.activeSort
				};
				if (this.$route.params.source == "createCollect") {
					params.owner = this.$store.state.did;
				}
				let res = await api.home.collectionInfo(params);
				let assetList;
			    if( res.data.assetListPage != null){
					assetList = res.data.assetListPage.list;
					this.amount = res.data.assetListPage.totalRow;
					let { totalPage} = res.data.assetListPage;
       			 	this.totalPage = totalPage;
				}
				this.collectItem = res.data;
			
				if (assetList != null && assetList.length > 0)
					this.list = this.list.concat(assetList);
				if( this.collectItem.owner != this.$store.state.did && res.data.assetListPage != null ){
						this.ownerName = res.data.assetListPage.list[0].userName;
						this.ownerBy = res.data.assetListPage.list[0].nftOwner;
									if (!this.ownerName) {
										this.ownerName =
											this.ownerBy.substr(0, 6) +
											"..." +
											this.ownerBy.substr(-6);
								}
				}
				
		
				

				if (
					(this.pageNumber == 1 || this.pageNumber == 0) &&
					this.list.length == 0
				) {
					this.empty = true;
				} else {
					this.empty = false;
				}
				this.loading = false;
				     this.isShowLoading = false
			},
			collection() {
				this.list = [];
				 this.collectSource = 5;
				this.pageNumber = 1;
				 this.isShowCheckbox ='';
				this.getColleectList();
			},
			
			onScroll(e) {
				const {
					scrollTop,
					clientHeight,
					scrollHeight,
				} = e.target.scrollingElement;
				if (
					scrollTop + clientHeight >= scrollHeight &&
					this.totalPage > this.pageNumber
				) {
					  if (!this.loading) {
        			this.pageNumber++;
      				}
					// this.pageNumber++;
					this.getColleectList();
				}
			},
			shareBtn() {
				this.openShare = true;
			},
			msgBtn() {
				this.currfriend = {
					fansName: this.collectItem.userName,
					fansAddress: this.collectItem.owner,
				};
				this.openmsg = true;
			},
	nftPlaintBtn() {
				this.nftComplaint = true;
				this.isshowComplaint = false
			},
		
		hideNfts(model){
				 
         this.isshowComplaint = false
         this.hideHandle(model)
			},
			hideCreator(model){
			
         this.isshowComplaint = false
         this.hideHandle(model)
      },
      async hideHandle(model){
				let params = {
					authenticationAddress:this.$store.state.did
				}
				if(model == 'folder'){
					params.blockContractCollectionId= this.collectItem.contractCollectionId
				}else{
					params.blockOwner = this.collectItem.owner
				}
				let res = await api.home.hideNfts(params)
				if(res.success){
					this.$toast("success", this.$t("success"))
				}else{
					 this.$toast("error", "Activate faild")
				}

			},
    openNftcComp(e) {
      this.nftComplaint = e;
	},
	//申诉弹层
			AppealBtn() {
				if(	this.collectItem.contractStatus == 4){
					this.$toast("error", 'Appealed');
				}else{
					this.Appeal = true;
				}	
				
			},
			Open(e) {
				this.Appeal = e;
			},
	 openBanprompt(e){
         this.Banprompt = e
	  },
	  
	  subCancel() {
		  this.list = [];
        	this.pageNumber = 1;
		   this.isShowCheckbox = "";
			this.collectSource = 5;
			this.getColleectList();
  
    },
    subSure() {
		
      this.offSaleTokenIds = [];
      this.onSaleTokenIds = [];   
       for(let i=0;i<this.nftList.length;i++){
           this.offSaleTokenIds.push(this.nftList[i].contractAddress)
           this.onSaleTokenIds.push(this.nftList[i].contractAddress)
      }
      if(this.offSaleTokenIds.length>0)
      {
        
        this.removefolder =true
      }else{
       this.removefolder =false
        this.$toast("error", this.$t("choose_nft"));
     
      }

    
	},
	openPutOnSale(e) {
      this.openputonsale = e;
    },
			editCollection(){
				// debugger
				//    this.createType = this.collectItem.nftType
       			// 	this.NFTInfo.collectionImgUrl = this.collectItem.imgUrl
       			// 	 this.NFTInfo.name = this.collectItem.name
       			// 	 this.NFTInfo.linkUrl = this.collectItem.linkUrl?this.collectItem.linkUrl:this.collectItem.address
       			// 	 this.NFTInfo.description = this.collectItem.description
				// 	this.NFTInfo.collectionMetadata = this.collectItem.collectionMetadata
				// 	this.NFTInfo.id = this.collectItem.id
				// 	this.NFTInfo.category = this.collectItem.category
				// 	this.NFTInfo.nftAddress = this.collectItem.nftAddress
				
			
				 this.NFTInfo.collectionImgUrl =  this.collectItem.imgUrl
				this.NFTInfo.name = this.collectItem.name
				this.NFTInfo.linkUrl = this.collectItem.linkUrl?this.collectItem.linkUrl:this.collectItem.address
				this.NFTInfo.description = this.collectItem.description
				this.NFTInfo.collectionMetadata =  this.collectItem.collectionMetadata
				this.NFTInfo.id = this.collectItem.id
				this.NFTInfo.contractCollectionId = this.collectItem.contractCollectionId
				this.openCreateFolder = true


			},
			 openCreateFolders(e){
      this.openCreateFolder = e
    },
			//弹框关闭后获取open值
			OpenMsg(e) {
				this.openmsg = e;
			},
			openCarousel() {
				this.opencard = true;
			},
			OpenCard(e) {
				this.opencard = e;
			},
			 OpenCreateCollection(e){
     
         this.openCreateCollection = e
    },
			toCopyLink() {
				// http://web.upticknft.com:8084/#/saledetail?id=7666
				var input = document.createElement("input");
				input.value =
					process.env.VUE_APP_BASE_URL +
					`#/saledetail?id=${this.$route.query.id}`;

				document.body.appendChild(input);
				input.select();
				document.execCommand("copy");
				document.body.removeChild(input);
				this.isCopy = true;
				setTimeout(() => (this.isCopy = false), 100);
			this.$toast("success", this.$t('copt_success'))
				//xxl TODO need to add tip
			},

			toTweet() {
				// alert("abc ...");
				var meta = document.createElement("meta");
				//meta.setAttribute('property', 'og:image');
				meta.setAttribute("name", "twitter:image:src");
				meta.setAttribute(
					"content",
					"https://cdn.cms-twdigitalassets.com/content/dam/developer-twitter/redesign-2021-images/og-social-card/devwebsite_card_tn.jpg.twimg.768.jpg"
				);
				document.getElementsByTagName("head")[0].appendChild(meta);

				//window.open("http://twitter.com/share?text=Im Sharing on Twitter&url=http://stackoverflow.com/users/2943186/youssef-subehi&hashtags=stackoverflow,example,youssefusf")
				window.open(
					"https://twitter.com/intent/tweet?text=Hello%20world&url=http://graphics8.nytimes.com/images/2012/02/19/us/19whitney-span/19whitney-span-articleLarge.jpg"
				);
				//window.open("https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fdeveloper.twitter.com%2F&amp;ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&amp;text=Guides%20%7C%20Docs%20%7C%20Twitter%20Developer%20Platform&amp;url=https%3A%2F%2Fdeveloper.twitter.com%2Fen%2Fdocs%2Ftwitter-for-websites%2Ftweet-button%2Foverview")
			},
			 async showNoRead(){
    const json = localStorage.getItem("key_user");
    if (json) {
      let res2 = await api.home.isNewMessage({address:this.$store.state.did});
      let newMsgCount = res2.data;


     // 有新消息进行弹窗
     if(newMsgCount >0){
        let params={
        pageSize: 10,
        pageNumber: 1,
        owner:this.$store.state.did,
		isRead:false,
		pushTypes:getSettingArr()
      };
      let res = await api.home.sysMessage(params)
      console.log("wxl --- sysMessage",res)
      if(res.data){
         let list = res.data.list
         for (let index = 0; index < list.length; index++) {
           setTimeout(() => {
              this.$mtip({
                title:list[index].pushContract,
              });
           }, 1000);
           
         }
      }      
    }
   }
   window.eventBus.$emit("ViewMessage", true);
   },
   onLangChange(){
	   this.selectList= [{
                    value: '0',
                    label:this.$t('market_filter_all')
                },
                {
                    value: '1',
                    label: this.$t('otherpageTabsPageOnsale')
                },
                {
                    value: '3',
                    label: this.$t('collection_tag_notonsale')
                },
                {
                    value: '4',
                    label: this.$t('token_state_end')
                },
            ];

   }
		},
	};
</script>

<style lang="scss" scoped>

	.contain {
		padding: 0;
		margin: 0;

		.top {
			max-width: 1220px;
			height: 300px;
			margin: 0 auto;
			position: relative;
		

			.ban {
				max-width: 1220px;
				height: 300px;
				margin: 0 auto;
				opacity: 0.8;
			

				img {
					width: 100%;
					height: 100%;
					display: block;
					object-fit: cover;
						border-radius: 15px;
				}
			}

			.topbox {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				margin: 0 auto;
				border-radius: 15px;

				.img {
					width: 370px;
					height: 240px;
					margin-right: 90px;
					margin-left: 105px;
				
					img {
						object-fit: contain;
						display: block;
						margin-top: 30px;
						width: 100%;
						height: 100%;
						border-radius: 15px;
						// border: solid 4px #ffffff;
						
					}
				}

				.right {
					padding: 35px 0 25px;

					.rt_icon {
						.oneicon {
							width: 20px;
							height: 20px;
						}
					}

					.rb_icon {
						position: absolute;
						top: 30px;
						right: 30px;

						.twoicon {
							width: 22px;
							height: 26px;
						}
					}

					.message {
						position: absolute;
						cursor: pointer;
						top: 30px;
						right: 80px;
					}
					
					.complaint{
						position: absolute;
						cursor: pointer;
						top: 30px;
						right: 130px;

					}
					.icon_collect{
						position: absolute;
						cursor: pointer;
						bottom: 30px;
						right: 30px;
					}
					.nfttype{
						width: 40px;
						height: 19px;
						background-color: #ffffff;
						border-radius: 9px;
						position: absolute;
						bottom: 30px;
						right: 70px;
						text-align: center;
						.type{
							font-family: Helvetica;
						font-size: 12px;
						font-weight: bold;
						font-stretch: normal;
						line-height: 19px;
						letter-spacing: 0px;
						color: #270645;
						
						
					}
					}

					.box {
						min-width: 543px;
						// margin: auto;
						margin: 25px auto;
						.toMarket{
							cursor: pointer;
							position: absolute;
							top:-23px;
							right:25px;
							font-family: Helvetica;

							font-size: 15px;
							font-weight: bold;
							font-stretch: normal;
							line-height: 100px;
							letter-spacing: 0px;
							color: #ffffff;
						}
						.ava {
							align-items: center;

							.nameBy {
								min-height: 17px;
								font-family: Helvetica;
								font-size: 14px;
								font-stretch: normal;
								letter-spacing: 0px;
								color: #ffffff;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
							}

							.name {
								min-height: 17px;
								font-family: Helvetica;
								font-size: 15px;
								font-weight: bold;
								font-stretch: normal;
								letter-spacing: 0px;
								color: #ffffff;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
							}
						}

						.desc {
							margin-bottom: 30px;
							margin-right: 30px;
							width: 550px;
							font-family: Helvetica;
							font-size: 40px;
							font-weight: bold;
							font-stretch: normal;
							letter-spacing: 0px;
							color: #ffffff;
							word-break: break-all;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							overflow: hidden;
							-webkit-box-orient: vertical;
						}

						.nftcount {
							margin-right:-44px;
							.num {
								font-family: Helvetica;
								font-weight: bold;
								font-size: 15px;
								color: #ffffff;
							}

							.sub {
								font-family: Helvetica;
								font-weight: bold;
								font-size: 15px;
								color: #ffffff;
							}
						}
						 .explain {
							width: 110px;
							height: 30px;
							background-color: #270645;
							border-radius: 3px;
							position: absolute;
							top: 55px;
							right: 80px;
							span {
								font-family:Helvetica;
								font-size: 12px;
								font-weight: normal;
								font-stretch: normal;
								line-height: 15px;
								letter-spacing: 0px;
								color: #ffffff;
								margin-left: 13px;
								margin-right: 13px;
								}
    					}
						 .explains {
						width: 140px;
							height: 85px;
						background-color: #270645;
						position: absolute;
						top: 55px;
						right: 10px;
						line-height: 34px;
							text-align: left;
							z-index: 99;
							background-blend-mode: normal, normal;
							box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 9%);
							border-radius: 5px;
						.hideNfts {
							font-family: Helvetica;
							font-size: 13px;
							font-weight: normal;
							font-stretch: normal;
							line-height: 15px;
							letter-spacing: 0px;
							color: #ffffff;
							margin: 10px;
							cursor: pointer;
					
            }
    					}
						 .appealexplain {
							width: 185px;
							height: 56px;
							background-color: #270645;
							border-radius: 3px;
							position: absolute;
							top: 53px;
							right: 133px;
							span {
								font-family:Helvetica;
								font-size: 12px;
								font-weight: normal;
								font-stretch: normal;
								line-height: 15px;
								letter-spacing: 0px;
								color: #ffffff;
								margin-left: 13px;
								margin-right: 13px;
								}
    					}
						.explainCollect{
							width: 185px;
							height: 56px;
							background-color: #270645;
							border-radius: 3px;
							position: absolute;
							top: 275px;
							right: 30px;
							span {
								font-family:Helvetica;
								font-size: 12px;
								font-weight: normal;
								font-stretch: normal;
								line-height: 15px;
								letter-spacing: 0px;
								color: #ffffff;
								margin-left: 13px;
								margin-right: 13px;
								}
						}
						
					}
				}
			}
		}

		.tit {
			text-align: center;
			margin-bottom: 29px;

			p {
				min-height: 100px;
				margin: 29px auto 0;
				max-width: 1018px;
				min-height: 0px;
				font-family: Helvetica;
				font-size: 14px;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0px;
				color: #270645;
			}
		}

		.share {
			width: 166px;
			height: 161px;
			background-image: linear-gradient(#ffffff, #ffffff),
				linear-gradient(#6f58d9, #6f58d9);
			background-blend-mode: normal, normal;
			box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
			border-radius: 5px;
			position: absolute;
			right: 20px;
			top: 30px;
			z-index: 20000;

			img,
			.icon {
				display: inline-block;
				width: 25px;
				height: 25px;
			}

			span {
				font-family: "\5FAE\8F6F\96C5\9ED1";
				font-size: 13px;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0px;
				color: #270645;
			}
		}
		.totalbox {
    // max-width: 1600px;
    height: 90px;
    background-color: #270645;
     position: fixed;
     left:0;
  bottom: 0;
  width: 100%;
  line-height: var(--footer-height);
  z-index: floor($number: 999);
  //margin-left: -108px;
   .check{
	  position: absolute;;
	  left: 106px;
	  bottom: 35px;
	  &::v-deep .v-input__control .v-input__slot .v-label {
						font-family:Helvetica;
						font-size: 13px;
						font-weight: bold;
						font-stretch: normal;
						// line-height: 54px;
						letter-spacing: 0px;
						color:#ffffff;
					}
	  &::v-deep .v-input__control .v-input__slot .v-input--selection-controls__input .theme--light {
						
						color:#ffffff;
					}

  }

    p {
      margin-bottom: 0;
      margin-right: 40px;
      color: #ffffff;
    }
    .subcance3l {
      width: 75px;
      height: 36px;
      background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-style: solid;
      border-width: 2px;
     background-color: #7800f4;
      border-image-slice: 1;
      font-family:Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
      color: #270645;
    }
    .btnbox {
      width: 75px;
      height: 36px;
      box-sizing: border-box;
      padding: 2px;
      background-color: #7800f4;
      border-radius: 17px;

      .subcancel {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 17px;
        background: #fff;
        font-family: "\5FAE\8F6F\96C5\9ED1";
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0;
        color: #270645;
      }
    }
    .subsure {
      width: 75px;
      height: 36px;
     background-color: #7800f4;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
      border-radius: 18px;
      font-family:Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
      color: #ffffff;
      margin-right: 110px;
    }
  }

		.right_bottom {
			position: fixed;
			bottom: 120px;
			right: 50px;
			    z-index:200;

			img {
				display: block;
				width: 59px;
				height: 59px;
				margin-top: 35px;
			}
		}

		.cards-container {
			.search_create {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.clearmode {
					margin-left: 10px;

					.checkbox {
						display: flex;
						align-items: center;
					}

					.content {
						display: flex;
						align-items: center;
						margin-right: 40px;
						height: 83px;
						font-size: 14px;
						font-weight: bold;
					}
				
					
					.image {
					display: flex;
					align-items: center;
					cursor: pointer;
					.nft {
					width: 22px;
					height: 28px;
    }
  
    .collection {
      width: 25px;
      height: 25px;
      margin-left: 30px;
    }
     .top_tab {
        .btn {
          height: 25px;
          font-family:Helvetica;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: 0px;
          color: #766983;
          cursor:pointer;
        }
        .btn2 {
          height: 25px;
          font-family:Helvetica;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: 0px;
         color: #7800f4;;
          border-bottom: 3px solid  #7800f4;;
          padding-bottom: 8px;
        }
      }
 
  }
				}

				.create {
					
						&::v-deep .sort-select .rd.v-chip {
						box-shadow: none;
						border: none;
						margin-bottom: 2px;
						font-weight: bold;
					}
						.subOnsale{
						 
						width: 208px;
						height: 41px;
						background-color: #f7f7f7;
						border-radius: 20px;
						
						.titleName{
						font-family:Helvetica;
						font-size: 13px;
						font-weight: bold;
						font-stretch: normal;
						line-height: 35px;
						letter-spacing: 0px;
						color: #270645;
						margin-left: 30px;
						}
						.titleName::before{
							content: "";
							background-image: url(../../assets/icons/folder_remove.png);
							width: 20px;
							height: 20px;					
							background-size: 100%;
							display: inline-block;
							position: absolute;					
							margin-top: 6px;
							margin-left: -30px;
						
					}
          
					}
					
					.owner {
						display: flex;
						align-items: center;
				
						font-size: 14px;
						font-weight: bold;
						margin-top: 16px;

						span {
							color: #7800f4;
						}
						
					}
					  .refresh {
						height: 18px;
						width: 18px;
						margin-top: 16px;
						margin-left: 15px;
						}

						.go {
						transform: rotate(360deg);
						transition: all 2s;
						pointer-events: none;
						user-select: none;
						}
				}
			}

			.cardlist {
				display: flex;
				flex-wrap: wrap;
				  position: relative;
				 .check {
					// z-index: 200;
					position: relative;
					top: -370px;
					left: 10px;
					&::v-deep .v-input__control .v-input__slot .v-label {
						font-family:Helvetica;
						font-size: 13px;
						font-weight: bold;
						font-stretch: normal;
						// line-height: 54px;
						letter-spacing: 0px;
						color: #270645;
					}
    			}
				  .ownerCheck{
						position: relative;
					top: -285px;
					left: 10px;
					&::v-deep .v-input__control .v-input__slot .v-label {
						font-family:Helvetica;
						font-size: 13px;
						font-weight: bold;
						font-stretch: normal;
						// line-height: 54px;
						letter-spacing: 0px;
						color: #270645;
					}

				}
			

				.v-card {
					margin-right: 23px;
					margin-bottom: 30px;

					&:nth-child(5n) {
						margin-right: 0;
					}
				}

				.v-card.mobile {
					width: 100%;
					margin-right: 0;
				}

				.empty {
					width: 100%;
					height: 500px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}

		&.mobile {
			width: 100%;

			.card {
				width: 100%;
				margin-right: unset;
				justify-content: space-between;
			}

			.cards-container {
				margin-right: unset;
				.search_create{
						display: flex;
						flex-direction: column!important;
					.clearmode{
						display: flex;
						flex-direction: column!important;
						.buttons{
							.subOnsale{
								width: 115px;
							}
						
					}


					}
					.create{
						margin-top: 10px;
						.subOnsale{
							width: 150px!important;
							.titleName{
						font-family:Helvetica;
						font-size: 12px;
					
						}
						}

					}
					

				}
			}

			.v-card {
				margin-right: 10px !important;
			width: 155px;
			}

			.img {
				margin-right: unset !important;
			}

			.tit {
				margin-bottom: 20px;
			}

			.search_create {
				margin-bottom: 20px;
			}

			.desc {
				font-size: 30px !important;
				width: 100% !important;
				word-break: keep-all !important ;
				word-wrap: break-word; 
			}

			.createdby {
				margin-left: 15px !important;
			}
			.top{
				.topbox{
					.right{
						width: 100%;
						.box{
							min-width: 0px;
							margin: 40px 25px;

						}

					}

			}
			}
	.totalbox{
     .subsure{
       margin-right: 10px;
     }
     p{
       margin-right: 10px;
     }
     .check{
           position: absolute;
    left: 0px;
    bottom: 33px;
     }

   }
			
		}
	}
</style>
