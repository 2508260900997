<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="top">
        <h3 class="end">{{ $t("Remove_folder") }}</h3>
        <!-- <h3 class="content">
          {{ $t("popupBurnYourMinted") }}
        </h3> -->
      </div>
      <div class="footer">
        <button class="sub" submit @click="subCommit">
          {{ $t("popupTransferCommit") }}
        </button>
      </div>
    </v-card>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from "@/api";
export default {
  data: () => ({
    open: true,
  }),
  props: {
 
    tokenId: {
      type: String,
    },
    contractCollectionIds:{
        type: Number,
    },
    offSaleTokenIds:{
        type:Array ,
    },
    parentValue:{
      type:Object
    }
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted(){
      console.log("666666666666",this.offSaleTokenIds,this.type)
  },
  methods: {
    //提交转送数据
    async subCommit() {
     let nftIds = '';
        if (this.offSaleTokenIds.length > 0) {
          for (let i = 0; i < this.offSaleTokenIds.length; i++) {
            nftIds += this.offSaleTokenIds[i] + ',';
          }
        }
        if(nftIds)
        {
          nftIds = nftIds.substring(0, nftIds.lastIndexOf(','))	
        }
      
         let params = {
           contractAddress: nftIds,
           contractCollectionId:this.parentValue.contractCollectionId
        };
        let res = await api.home.removeFolder(params);
     
      if (res.success == true) {
        this.$toast("success", this.$t("success")).then(() => {
          this.$emit("getOpen", false);
        });
        setTimeout(() => {
          this.$router.push({ name: "PersonalPage" });

        }, 2000);
      } else {
        this.$toast("error", this.$t(res.msg));
      }

      // console.log(res)
      // this.$refs.promptBox.show(res.msg);
    },
    //关闭弹框，发送open值
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 274px;
  width: 600px;
  .top {
    display: flex;
    flex-direction: column;
    margin: 42px 70px 30px;
    .end {
      margin: 0 auto 23px;
      // width: 117px;
      // height: 20px;
      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }

    .content {
      height: 72px;
      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      text-align: center;
      color: #270645;
    }
  }
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }
  .footer {
    margin: 0 auto;
    .sub {
      margin: 30px auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(
          90deg,
          #d300fd 0%,
          #a439fe 26%,
          #7471ff 52%,
          #00fdcf 100%
        ),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .top {
      width: 100%;
      margin: 30px 10% 0;

      .content {
        width: 80%;
        height: auto;
        word-wrap: break-word;
      }
    }
    .footer {
      width: 100%;
      margin-left: 10%;
      .sub {
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}
</style>




